import React from 'react'
import PropTypes from 'prop-types'

import { theme } from '@theme'
import styled from '@emotion/styled'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Root = styled.div`
  position: relative;
  ${(p) => (p.textAlign === 'Left' ? '' : '')}
  ${(p) => (p.textAlign === 'Center' ? 'text-align: center;' : '')}
  ${(p) => (p.textAlign === 'Right' ? 'text-align: right;' : '')}

  ${(p) =>
    p.textSize === 'BIG'
      ? `
  
    h2 {
    ${theme.font.caps04}
    }
    h3 {
      ${theme.font.caps05}
    }
    h4 {
      ${theme.font.caps06}
    }
  
  `
      : `
  
    h2 {
    ${theme.font.caps07}
    }
    h3 {
      ${theme.font.caps07}
    }
    h4 {
      ${theme.font.caps07}
    }
  `}
  p,
  ul,
  ol {
    b {
      font-weight: 500;
    }
    ${(p) => (p.textSize === 'BIG' ? theme.font.body01 : '')}
    ${(p) => (p.textSize === 'REGULAR' ? theme.font.body02 : '')}
    ${(p) => (p.textSize === 'SMALL' ? theme.font.body03 : '')}
  }
  h2,
  h3,
  h4 {
    margin-bottom: 0.5em;
  }

  ul,
  ol,
  p {
    & + p,
    & + ul,
    & + ol {
      margin-top: 1em;
    }
    & + h2 {
      margin-top: 3em;
    }
    & + h3,
    & + h4 {
      margin-top: 2em;
    }
  }
  a {
    text-decoration: underline;
  }
  u {
    text-decoration: underline;
  }
  ul {
    padding-left: 1.1em;
    list-style: disc;
  }
  ol {
    list-style: decimal;
    padding-left: 1.1em;
  }
  blockquote {
    text-align: center;
    margin: 3em auto;
    &,
    p {
      ${theme.font.body01}
      max-width: 500px;
    }
  }
`

const RichText = ({ json, plainHtml, textSize = 'REGULAR', textAlign = 'Left' }) => {
  if (!json && !plainHtml) {
    return null
  }

  const transformNewLinesToBrTags = (text) => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  }

  return (
    <Root textAlign={textAlign} textSize={textSize}>
      {json && documentToReactComponents(json, { renderText: transformNewLinesToBrTags })}
      {plainHtml && <div dangerouslySetInnerHTML={{ __html: plainHtml }} />}
    </Root>
  )
}

RichText.propTypes = {
  json: PropTypes.object,
  plainHtml: PropTypes.string,
  textSize: PropTypes.oneOf(['SMALL', 'REGULAR', 'BIG']),
  textAlign: PropTypes.oneOf(['Left', 'Center', 'Right'])
}

export default RichText
