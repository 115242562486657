import React, { useState, useEffect } from 'react'
import { ButtonBlock, ButtonColor, ButtonPrimary } from '@button'
import Image from '@image'
import styled from '@emotion/styled'
import { theme, mq, lin } from '@theme'

import Marquee from 'react-fast-marquee'
import { ButtonSecondary } from '@button'

const Root = styled.div`
  position: relative;
  ${(p) => (p.mobileRatio ? `height: ${(1 / p.mobileRatio) * 100}vw;` : ``)}
  ${(p) =>
    p.desktopRatio
      ? `
  ${mq['lg']} {
    height: ${(1 / p.desktopRatio) * 100}vw;
  }
  `
      : ``}
  background-color: ${(p) => p.backgroundColor ?? 'white'};
  color: ${(p) => p.textColor ?? 'black'};
`
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  display: grid;
  ${lin('font-size', 20, 55)}
  padding: 0.75em 0;
  grid-gap: 0.75em;
  opacity: ${(props) => (props.isLoaded ? 1 : 0)};
  transition: all 50ms;
  pointer-events: none;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 100%;
`
const MarqueeWrap = styled.div`
  ${theme.font.caps04}
  ${lin('font-size', 20, 55)}
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
  opacity: 0.99;
  color: currentColor;
  .marquee-container {
    overflow: hidden;
  }
  .marquee {
    margin-right: 0.25em;
  }
`

const SectionMarqueeBanner = ({
  movingText,
  image,
  imageMobile,
  buttonLabel,
  buttonHref,
  priority,
  backgroundColor,
  textColor,
  desktopRatio,
  mobileRatio
}) => {
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  const length = 3
  for (let i = 0; i < length; i++) {
    movingText = movingText + ' ' + movingText
  }

  const imageElem = (
    <>
      {image && (
        <Image image={image} sizes={'100vw'} priority={priority} layout={'fill'} className={'hide-on-mobile'} />
      )}
      {imageMobile && (
        <Image image={imageMobile} sizes={'100vw'} priority={priority} layout={'fill'} className={'hide-on-desktop'} />
      )}
    </>
  )

  return (
    <Root
      desktopRatio={desktopRatio ?? null}
      mobileRatio={mobileRatio ?? null}
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      <Background>
        {buttonHref ? (
          <ButtonBlock href={buttonHref} style={{ height: '100%' }}>
            {imageElem}
          </ButtonBlock>
        ) : (
          imageElem
        )}
      </Background>

      <Content isLoaded={isLoaded}>
        <MarqueeWrap>
          <Marquee gradient={false} speed={50}>
            {movingText}
          </Marquee>
        </MarqueeWrap>

        {buttonLabel && buttonLabel !== '' && (
          <ButtonColor href={buttonHref} color={textColor} style={{ pointerEvents: 'all', zIndex: 1 }}>
            {buttonLabel}
          </ButtonColor>
        )}
      </Content>
    </Root>
  )
}

export default SectionMarqueeBanner
