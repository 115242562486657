import React from 'react'

const IconArrow = ({ width = 24, height = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width + 'px'} height={height + 'px'}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
    </svg>
  )
}

export default IconArrow
