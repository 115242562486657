import React from 'react'
import styled from '@emotion/styled'
import RichText from '@/components/RichText'
import Container from '@/components/Container'

const Root = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 880px;
`
const SectionJournalText = ({ content, textAlign }) => {
  return (
    <Container>
      <Root>
        <RichText json={content.json} textAlign={textAlign} textSize={'BIG'} />
      </Root>
    </Container>
  )
}

export default SectionJournalText
