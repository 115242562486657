import React, { useRef, useEffect, useState } from 'react'
import { ButtonBlock } from '@button'
import Image from '@image'
import styled from '@emotion/styled'
import { theme, mq } from '@theme'
import Container from '@/components/Container'
import smoothscroll from 'smoothscroll-polyfill'
import IconArrowLeft from '@/components/_icons/IconArrowLeft'
import IconArrowRight from '@/components/_icons/IconArrowRight'

const Root = styled.div`
  position: relative;
  ${theme.size.l('margin-top')}
  ${theme.size.l('margin-bottom')}
`
const MainGrid = styled.div`
  position: relative;
  display: grid;
  overflow-x: auto;
  scroll-snap-type: x proximity;

  &::-webkit-scrollbar {
    display: none;
  }
`
const InnerGrid = styled.div`
  z-index: 0;
  display: flex;
  ${theme.size.gutter('grid-column-gap')}
  ${theme.size.containerMargin('padding-left')}
  & > div {
    width: 50vw;
    ${mq['lg']} {
      width: auto;
    }
  }
  ${mq['lg']} {
    display: grid;
    grid-auto-columns: 30%;
    grid-auto-flow: column;
  }
  &:after {
    display: block;
    content: '';
    ${theme.size.containerMarginMinusGutter('width')}
  }
`
const Slide = styled.div`
  scroll-snap-align: center;
`

const TextWrap = styled.div`
  ${theme.size.m('margin-bottom')}
  grid-column: span ${(props) => props.itemsNumber};
  max-width: 670px;
  ${mq['lg']} {
    order: 1;
  }
`
const Title = styled.h2`
  ${theme.font.caps04}
`
const ItemText = styled.div`
  ${theme.font.caps07}
  ${theme.size.xs('margin-top')}
`
const Arrow = styled.button`
  pointer-events: all;
  width: 56px;
  height: 56px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  position: absolute;
  transition: opacity 100ms;
  svg {
    transition: opacity 100ms;
  }
  &:hover svg {
    opacity: 0.5;
  }
  ${(p) => (p.hidden ? 'opacity: 0; pointer-events: none;' : '')};
`
const ScrollWrap = styled.div`
  position: relative;
  width: 100vw;
  ${mq['lg']} {
    width: 100%;
  }
`
const Controls = styled.div`
  display: none;
  ${mq['lg']} {
    display: flex;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    align-items: center;
    justify-content: space-between;
  }
`
const Track = styled.div`
  width: 100%;
  height: 2px;
  background: ${theme.colors.mono100};
  position: relative;
  ${theme.size.m('margin-top')}
  ${(p) => (p.items < 2 ? 'display: none;' : 'display: block;')}
${mq['lg']} {
    ${(p) => (p.items < 4 ? 'display: none;' : 'display: block;')}
  }
`
const Indicator = styled.div`
  width: ${(p) => (2 / p.items) * 100}%;
  ${mq['lg']} {
    width: ${(p) => (3 / p.items) * 100}%;
  }
  height: 100%;
  background: ${theme.colors.mono800};
`
const SectionPortraitImages = ({ title, imagesCollection }) => {
  const [isLeftArrowHidden, setLeftArrowHidden] = useState(true)
  const [isRightArrowHidden, setRightArrowHidden] = useState(false)

  const refSlider = useRef(null)
  const refTrack = useRef(null)

  const scrollSlides = (count) => {
    const slide1 = refSlider.current.firstChild.childNodes[0]
    const slide2 = refSlider.current.firstChild.childNodes[1]
    const slideWidth = slide1.getBoundingClientRect().width
    const gutterWidth = slide2.getBoundingClientRect().left - slide1.getBoundingClientRect().right
    const destination = refSlider.current.scrollLeft + count * slideWidth + count * gutterWidth
    refSlider.current.scrollTo({
      left: destination,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    smoothscroll.polyfill()
    const setupSlider = () => {
      const progress =
        1 -
        (refSlider.current.scrollWidth + refSlider.current.firstChild.getBoundingClientRect().x - window.innerWidth) /
          (refSlider.current.scrollWidth - window.innerWidth)

      if (progress <= 0.02) {
        setLeftArrowHidden(true)
      } else {
        setLeftArrowHidden(false)
      }
      if (progress >= 0.98) {
        setRightArrowHidden(true)
      } else {
        setRightArrowHidden(false)
      }

      const trackWidth = refTrack.current.getBoundingClientRect().width
      const indicatorWidth = refTrack.current.firstChild.getBoundingClientRect().width

      refTrack.current.firstChild.style.transform = `translateX(${progress * (trackWidth - indicatorWidth)}px)`
    }
    const handleSliderScroll = (e) => {
      setupSlider()
    }
    const handleResize = () => {
      setupSlider()
    }
    refSlider.current.addEventListener('scroll', handleSliderScroll)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Root>
      {title && (
        <Container>
          <TextWrap>{title && <Title>{title}</Title>}</TextWrap>
        </Container>
      )}

      <ScrollWrap>
        {imagesCollection.items.length > 3 && (
          <Controls>
            <Arrow onClick={() => scrollSlides(-1)} hidden={isLeftArrowHidden}>
              <IconArrowLeft />
            </Arrow>
            <Arrow onClick={() => scrollSlides(1)} hidden={isRightArrowHidden} style={{ right: 0 }}>
              <IconArrowRight />
            </Arrow>
          </Controls>
        )}

        <MainGrid ref={refSlider}>
          <InnerGrid itemsNumber={imagesCollection.items.length}>
            {imagesCollection.items.map((item, i) => {
              const _item = (
                <div>
                  <Image image={item.image} sizes={'100vw'} crop={'portrait'} />
                  {item.text && <ItemText>{item.text}</ItemText>}
                </div>
              )

              if (item.href) {
                return (
                  <Slide key={i}>
                    <ButtonBlock href={item.href}>{_item}</ButtonBlock>
                  </Slide>
                )
              } else {
                return <Slide key={i}>{_item}</Slide>
              }
            })}
          </InnerGrid>
        </MainGrid>
      </ScrollWrap>

      <Container>
        <Track ref={refTrack} items={imagesCollection.items.length}>
          <Indicator items={imagesCollection.items.length} />
        </Track>
      </Container>
    </Root>
  )
}

export default SectionPortraitImages
